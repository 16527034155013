import Columns from "components/shared/mediaText/MediaTextColumns"
import { Subtitle, Title, TitleWrap } from "components/shared/mediaText/shared"
import { RoundedTitle } from "components/shared/panda/RoundedTitle"
import PortableTitle from "components/shared/PortableTitle"
import { parseBackground, useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import { MediaText1 } from "schemas"
import { Container, styled, VStack } from "styled-system/jsx"
import { token } from "styled-system/tokens"

type Props = MediaText1

const getBox1Color = (style: string) => {
  switch (style) {
    case "2":
      return "linear-gradient(0deg, #FF9331 0%, #FF9331 100%), linear-gradient(121deg, #FF9331 23.91%, #FFCA85 143.2%)"
    case "3":
      return "linear-gradient(0deg, #6332DF 0%, #6332DF 100%), linear-gradient(0deg, #8C60FA 0%, #8C60FA 100%), linear-gradient(120deg, #6332DF 57.47%, #8C60FA 101.1%)"
    default:
      return "linear-gradient(0deg, rgba(24, 0, 217, 0.87) 0%, rgba(24, 0, 217, 0.87) 100%), linear-gradient(122deg, #1800D9 -1.38%, #060086 96.81%)"
  }
}

const getBox2Color = (style: string) => {
  switch (style) {
    case "2":
      return "linear-gradient(92deg, #8C60FA 0%, #6332DF 93.15%)"
    case "3":
      return "linear-gradient(272deg, #DB7519 1.3%, #FF9331 99.13%)"
    default:
      return "linear-gradient(92deg, #0CDCB7 0%, #07A286 90.37%)"
  }
}

const getHighlightColor = (style: string) => {
  switch (style) {
    case "2":
      return "primary.2"
    case "3":
      return "secondary.3"
    default:
      return "#0BDCB7"
  }
}

function MediaText1({
  subtitle,
  title,
  contentSubtitle,
  contentTitle,
  body,
  bottomBody,
  image,
  position,
  hideMediaMobile,
  style,

  sizingTop,
  sizingBottom,
  sizingTopMobile,
  sizingBottomMobile,
  backgroundColor1,
  backgroundColor2,
  backgroundStyle,
}: Props) {
  return (
    <styled.div
      data-block="mediaText1"
      style={
        {
          background: parseBackground(
            backgroundColor1,
            backgroundColor2,
            backgroundStyle
          ),
          "--pt": useSizing(sizingTop) || "64px",
          "--pb": useSizing(sizingBottom) || "64px",
          "--ptm": useSizing(sizingTopMobile),
          "--pbm": useSizing(sizingBottomMobile),
        } as React.CSSProperties
      }
      css={{
        pt: "var(--ptm, var(--pt))",
        pb: "var(--pbm, var(--pb))",
        bgColor: "rgba(136, 156, 255, 0.29)",

        lg: {
          pt: "var(--pt)",
          pb: "var(--pb)",
        },
      }}>
      <Container>
        {(title || subtitle) && (
          <TitleWrap>
            {subtitle && <Subtitle mb="8px">{subtitle}</Subtitle>}
            {title && <Title>{title}</Title>}
          </TitleWrap>
        )}

        <Columns
          position={position}
          media={
            image?.asset && (
              <styled.img
                hideBelow={hideMediaMobile && "md"}
                alt={image.alt || ""}
                src={urlForImage(image).url()}
              />
            )
          }
          content={
            <VStack gap={{ base: "24px", lg: "32px" }} color="white">
              <styled.div
                style={{
                  background: getBox1Color(style),
                }}
                css={{
                  width: "100%",
                  p: "18px 10px 20px",
                  rounded: "11px",
                  textAlign: "center",

                  lg: {
                    p: "30px 20px 40px",
                    rounded: "20px",
                  },
                }}>
                <RoundedTitle
                  responsive
                  style={{
                    color: token.var(
                      `colors.${getHighlightColor(style)}`,
                      getHighlightColor(style)
                    ),
                  }}>
                  {contentSubtitle}
                </RoundedTitle>
                <PortableTitle
                  as="div"
                  css={{
                    fontFamily: "heading",
                    fontSize: "22px",
                    fontWeight: 600,
                    lineHeight: "126%",
                    mb: "8px",

                    lg: {
                      fontSize: "40px",
                      mb: "16px",
                    },
                  }}
                  altColor={getHighlightColor(style)}>
                  {contentTitle}
                </PortableTitle>
                <styled.p
                  css={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "133%",

                    lg: {
                      fontSize: "20px",
                    },
                  }}>
                  {body}
                </styled.p>
              </styled.div>
              <styled.div
                style={
                  {
                    "--bgGradient": getBox2Color(style),
                  } as React.CSSProperties
                }
                css={{
                  width: "100%",
                  bgGradient: "var(--bgGradient)",
                  padding: "8px 16px",
                  rounded: "11px",

                  lg: {
                    rounded: "20px",
                    padding: "16px 32px",
                  },
                }}>
                <PortableTitle
                  as="div"
                  css={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "133%",

                    "& em": {
                      fontWeight: "bold",
                    },

                    lg: {
                      fontSize: "25px",
                    },
                  }}>
                  {bottomBody}
                </PortableTitle>
              </styled.div>
            </VStack>
          }
        />
      </Container>
    </styled.div>
  )
}

export default MediaText1
export type { Props as MediaText1Props }
